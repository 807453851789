import React from 'react';
import Layer3D from './layer3D';

export function compareVertices(v0, v1) {
  return v0.x === v1.x ? v0.y - v1.y : v0.x - v1.x;
}

export default function Scene3D({
  scene,
  catalog,
  width,
  height,
  mode,
  frontRect,
  selectedLayer,
  line_length,
  ceilHeight,
  scale,
  minX,
  minY,
  maxX,
  maxY,
}) {
  let i = 0,
    grids = [];
  for (let x = -width / 2; x <= width / 2; x = x + 30) {
    for (let y = -height / 2; y <= height / 2; y = y + 30) {
      if (
        Math.abs(x) > frontRect.width / 2 ||
        Math.abs(y) > frontRect.height / 2
      ) {
        grids.push(
          <circle key={`grid_${i}`} cx={x} cy={y} r={2} fill="#bbb" />
        );
        i++;
      }
    }
  }
  return (
    <g>
      {/* <polygon points={`-${width / 2},-${height / 2} ${width / 2},-${height / 2} ${width / 2},-${frontRect.height / 2} -${width / 2},-${frontRect.height / 2}`} fill="white" />
      <polygon points={`-${width / 2},${height / 2} ${width / 2},${height / 2} ${width / 2},${frontRect.height / 2} -${width / 2},${frontRect.height / 2}`} fill="white" />
      <polygon points={`-${width / 2},-${height / 2} -${frontRect.width / 2},-${height / 2} -${frontRect.width / 2},${height / 2} -${width / 2},${height / 2}`} fill="white" />
      <polygon points={`${width / 2},-${height / 2} ${frontRect.width / 2},-${height / 2} ${frontRect.width / 2},${height / 2} ${width / 2},${height / 2}`} fill="white" /> */}
      {grids}
      <Layer3D
        key={selectedLayer.id}
        layer={selectedLayer}
        frontRect={frontRect}
        lineLength={line_length}
        ceilHeight={ceilHeight.toFixed(0)}
        catalog={catalog}
        scene={scene}
        scale={scale}
        minX={minX}
        minY={minY}
        maxX={maxX}
        maxY={maxY}
        mode={mode}
      />
    </g>
  );
}
