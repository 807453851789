import React from 'react';
import * as S from './styles';
import './savedesign.style.css';
import { withStyles } from '@material-ui/core/styles';
import { VelocityTransitionGroup } from 'velocity-react';
import { DEFAULT_FONT_FAMILY, PROJECT_NAME_LENGTH_LIMIT, SECONDARY_PURPLE_COLOR, TEXT_COLOR_NEUTRAL_1, TEXT_COLOR_NEUTRAL_0, TEXT_COLOR_NEUTRAL_2 } from '../../../../constants';
import { emailValidation } from '../../../../utils/email-validator';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import SnackBar from '../../../atoms/Snackbar/index';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm{
    border-radius: 10px;
    width: 350px;
  }
`;

class SaveDesignPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: sessionStorage.getItem("email") || '',
      emailfocusOn: false,
      phone: sessionStorage.getItem("phone") || '',
      phonefocusOn: false,
      notes: '',
      firstName: sessionStorage.getItem("firstName") || '',
      firstNamefocusOn: false,
      lastNamefocusOn: false,
      lastName: sessionStorage.getItem("lastName") || '',
      projectNamefocusOn: false,
      projectName: 'Untitled',

      // error handling
      pjNameLimit: false,
      isSnackBarOpen: false,
      snackBarMessage: ""
    };

    this.timeoutId = null;

    functionBinder.call(this);

    function functionBinder() {
      this.onSave = this.onSave.bind(this);
      this.closePanel = this.closePanel.bind(this);
      this.projectNameChangeHandler = this.projectNameChangeHandler.bind(this);
    }
  }

  componentWillReceiveProps(newProps) {
    const email = sessionStorage.getItem('email');
    const phone = sessionStorage.getItem('phone');
    const firstName = sessionStorage.getItem('firstName');
    const lastName = sessionStorage.getItem('lastName');
    let projectName = sessionStorage.getItem('projectTitle');
    if (newProps.state.scene.title !== "") {
      projectName = newProps.state.scene.title || sessionStorage.getItem('projectTitle');
    }

    if ((newProps.visible !== this.props.visible) && newProps.visible) {
      if (email !== null && email !== 'temp_xxx@yyy.com') {
        this.setState({ email: email });
      }
      if(phone !== null){
        this.setState({phone: phone});
      }
      if(firstName !== null){
        this.setState({firstName: firstName});
      }
      if(lastName !== null){
        this.setState({lastName: lastName});
      }
      if (projectName !== null) {
        this.setState({ projectName });
      }
    }
  }

  onSave() {
    const { firstName, lastName, email, projectName, phone } = this.state;
    if (firstName === '' || lastName === '' || email === '' || projectName === '' || !emailValidation(email) || phone === '') {
      let msg;
      if (firstName === '') msg = 'Type your first name';
      else if (lastName === '') msg = 'Type your last name';
      else if (email === '') msg = 'Type your email address';
      else if (phone === '') msg = 'Type your phone number';
      else if (projectName === '') msg = 'Type your project name';
      else if (!emailValidation(email)) msg = 'Type your valid email address';
      this.setState({isSnackBarOpen: true, snackBarMessage: msg})
      return;
    }
    this.props.saveProject(this.state);
    this.props.handleSaveDesign(false);
    this.setState({ firstName: '', lastName: '', email: '', projectName: '', notes: '', phone: '' });
  }

  closePanel() {
    this.props.handleSaveDesign(false);
    this.props.setVisible(false);
    this.setState({ firstName: '', lastName: '', email: '', projectName: '', notes: '', phone: '' });
  }

  projectNameChangeHandler(e) {
    if (e.target.value.length >= PROJECT_NAME_LENGTH_LIMIT) {
      this.setState({ pjNameLimit: true });
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.setState({ pjNameLimit: false }), 2000);
    } else {
      this.setState({ projectName: e.target.value });
    }
  }
  render() {
    let { visible } = this.props;
    let {email, emailfocusOn, phone, phonefocusOn, notes, firstName, firstNamefocusOn, lastName, lastNamefocusOn, projectName, projectNamefocusOn, selectedDoorCategory, selectedDoorColor} = this.state;
    return (
      <StyledDialog open={visible} onClose={() => this.closePanel()}>
        <SnackBar isOpen={this.state.isSnackBarOpen} message={this.state.snackBarMessage} handleClose={()=>this.setState({isSnackBarOpen:false, snackBarMessage: ""})} />
        <S.DialogTitle>Save</S.DialogTitle>
        <S.DialogContent>Save your project to access it later via the email link.</S.DialogContent>
        <S.DialogContentSelection>
          <S.StyledInputWrapper>
            <S.StyledInput
              id="first_name_input"
              value={firstName}
              onChange={e => this.setState({ firstName: e.target.value })}
              onFocus = {e => this.setState({ firstNamefocusOn: true })}
              onBlur = {e => this.setState({ firstNamefocusOn: false })}
              placeholder = {firstNamefocusOn ? "Enter first name" : "First name"}
              required>
            </S.StyledInput>
            {(firstNamefocusOn || !!firstName.length) && <S.StyledInputHeadLabel style={{color: !firstNamefocusOn && TEXT_COLOR_NEUTRAL_2}}>First Name</S.StyledInputHeadLabel>}
            {!!firstName.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ firstName: '' })} />}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={lastName}
              onChange={e => this.setState({ lastName: e.target.value })}
              onFocus = {e => this.setState({ lastNamefocusOn: true })}
              onBlur = {e => this.setState({ lastNamefocusOn: false })}
              placeholder = {lastNamefocusOn ? "Enter last name" : "Last name"}
              required
            />
            {(lastNamefocusOn || !!lastName.length) && <S.StyledInputHeadLabel  style={{color: !lastNamefocusOn && TEXT_COLOR_NEUTRAL_2}}>Last Name</S.StyledInputHeadLabel>}
            {!!lastName.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ lastName: '' })} />}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={email}
              onChange={e => this.setState({ email: e.target.value })}
              onFocus = {e => this.setState({ emailfocusOn: true })}
              onBlur = {e => this.setState({ emailfocusOn: false })}
              placeholder = {emailfocusOn ? "Enter email address" : "Email Address"}
              required
            />
            {(emailfocusOn || !!email.length) && <S.StyledInputHeadLabel style={{color: !emailfocusOn && TEXT_COLOR_NEUTRAL_2}}>Email Address</S.StyledInputHeadLabel>}
            {!!email.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ email: '' })} />}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              value={phone}
              onChange={e => this.setState({ phone: (e.target.validity.valid || e.target.value.length === 0) ? e.target.value : this.state.phone })}
              onFocus = {e => this.setState({ phonefocusOn: true })}
              onBlur = {e => this.setState({ phonefocusOn: false })}
              pattern={"[0-9]*"}
              maxLength={50}
              placeholder  = { phonefocusOn ? "Enter phone number" : "Phone Number"}
              required
            />
            {(phonefocusOn || !!phone.length) && <S.StyledInputHeadLabel style={{color: !phonefocusOn && TEXT_COLOR_NEUTRAL_2}}>Phone Number</S.StyledInputHeadLabel>}
            {!!phone.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ phone: '' })} />}
          </S.StyledInputWrapper>
          <S.StyledInputWrapper>
            <S.StyledInput
              style={{fontWeight: 700, color: TEXT_COLOR_NEUTRAL_0}}
              value={projectName}
              onChange={e => this.setState({ projectName: e.target.value })}
              onFocus = {e => this.setState({ projectNamefocusOn: true })}
              onBlur = {e => this.setState({ projectNamefocusOn: false })}
              placeholder = {projectNamefocusOn ? "Enter project name" : "Project Name"}
              required
            />
            {(projectNamefocusOn || !!projectName.length) && <S.StyledInputHeadLabel style={{color: !projectNamefocusOn && TEXT_COLOR_NEUTRAL_2}}>Project Name</S.StyledInputHeadLabel>}
            {!!projectName.length && <S.PlusImage maskImage="/assets/img/svg/bottombar/arrow-plus.svg" onClick={() => this.setState({ projectName: '' })} />}
          </S.StyledInputWrapper>
          <div style={{ width: '100%', marginTop: 10 }}>
            <S.DialogTextArea
              value={notes}
              maxLength={200}
              placeholder="Notes(optional)"
              onChange={e => this.setState({ notes: e.target.value })}
            />
            <span style={{ fontFamily: DEFAULT_FONT_FAMILY, fontSize: 13, color: TEXT_COLOR_NEUTRAL_1, float: 'right', lineHeight: '18px', fontWeight: 400 }} >{notes.length}/200 characters</span>
          </div>
        </S.DialogContentSelection>
        <S.DialogAction>
          <S.Button onClick={() => this.closePanel()}>
            Cancel
          </S.Button>
          <S.Button onClick={this.onSave}>
            OK
          </S.Button>
        </S.DialogAction>
      </StyledDialog>
    )
  }
}


export default SaveDesignPopup
