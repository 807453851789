import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';
import { FormTextInput } from '../../style/export';
import IconButton from '@material-ui/core/IconButton';
import {
  DEFAULT_FONT_FAMILY,
  KEYBOARD_BUTTON_CODE,
  TEXT_COLOR_NEUTRAL_0,
  TEXT_COLOR_NEUTRAL_3
} from '../../../constants';
import { Drawer } from '@material-ui/core';

export default class Menubar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renameForm: false,
      renameTitle: '  ',
      saveAsBtnClicked: false,
      isSnackBarOpen: false,
      snackBarMessage: ''
    };
  }

  renameFormToggle(data, saveAsBtnClicked = false) {
    if (this.state.renameTitle !== '') {
      this.setState({
        renameForm: data,
        saveAsBtnClicked
      });
    } else {
      this.setState({
        isSnackBarOpen: true,
        snackBarMessage: 'This field is required'
      });
    }
  }

  onRenameTitleChange(ev) {
    this.setState({ renameTitle: ev.target.value });
    sessionStorage.setItem('projectTitle', ev.target.value);
    this.context.projectActions.rename(ev.target.value);
  }

  _closePanel() {
    this.props.setMenubarVisible(false);
  }

  render() {
    const { renameForm, renameTitle, saveAsBtnClicked } = this.state;

    let projectTitle = this.props.state.scene.title;

    return (
      <Drawer
        open={this.props.visible}
        onClose={() => this._closePanel()}
        variant="persistent"
      >
        <div
          style={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            height: '100vh',
            paddingRight: 20,
            paddingTop: 5,
            userSelect: 'none'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              onClick={() => this._closePanel()}
              style={{ marginLeft: '0.5em' }}
            >
              <img
                width="25px"
                style={{ transform: 'rotate(45deg)' }}
                src="/assets/img/svg/bottombar/arrow-plus.svg"
              />
            </IconButton>
            <S.LogoBox style={{ padding: 'unset' }}>
              <a href="https://www.thertastore.com" target="_blank">
                <img width="40px" src="/assets/img/rta/rta_logo_box_blue.jpg" />
              </a>
            </S.LogoBox>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: DEFAULT_FONT_FAMILY,
                fontSize: 13,
                fontWeight: 600,
                lineHeight: '18px',
                textAlign: 'center'
              }}
            >
              <p
                style={{
                  color: TEXT_COLOR_NEUTRAL_3,
                  fontSize: '16px',
                  fontWeight: 600,
                  lineHeight: '22px',
                  textAlign: 'center'
                }}
              >
                Drafts
              </p>
              <p style={{ color: TEXT_COLOR_NEUTRAL_0, margin: '0px 3px' }}>
                /
              </p>
              {renameForm ? (
                <div className="header-projectname-wrapper">
                  <S.InputStyle>
                    <FormTextInput
                      value={projectTitle ? projectTitle : renameTitle}
                      onChange={e => {
                        this.onRenameTitleChange(e);
                      }}
                      style={{
                        textAlign: 'left',
                        height: '32px',
                        border: 'unset',
                        width: 50
                      }}
                      onKeyDown={e => {
                        var keyCode = e.keyCode || e.which;
                        if (keyCode == KEYBOARD_BUTTON_CODE.ENTER) {
                          if (saveAsBtnClicked) {
                            saveProject(e);
                          }
                          this.renameFormToggle(false, false);
                        }
                      }}
                    />
                  </S.InputStyle>
                </div>
              ) : (
                <div className="header-projectname-wrapper">
                  <p
                    style={{ color: TEXT_COLOR_NEUTRAL_0, width: 50 }}
                    onClick={() =>
                      this.renameFormToggle(true, saveAsBtnClicked)
                    }
                  >
                    {projectTitle ? projectTitle : renameTitle}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '5px 15px',
              width: '100%'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: DEFAULT_FONT_FAMILY,
                lineHeight: '16px',
                textAlign: 'left',
                color: TEXT_COLOR_NEUTRAL_3,
                margin: '10px 0px'
              }}
            >
              Project settings
            </span>
            <S.SelectedBox
              onClick={() => {
                if (sessionStorage.getItem('asking')) this.props.newProject();
                else this.props.handleNewProject(true);
              }}
            >
              <img height={18} src="/assets/img/svg/menubar/new_project.svg" />
              <S.SelectTitle>New Project</S.SelectTitle>
            </S.SelectedBox>
            <S.SelectedBox
              onClick={() => {
                if (!this.props.match.params.token) {
                  this.props.setMyProjectsToLogin(true);
                  this.props.setSignOpen(true);
                } else {
                  this.props.setMyProjectsOpen(true);
                }
              }}
            >
              <img height={18} src="/assets/img/svg/menubar/my_projects.svg" />
              <S.SelectTitle>My Projects</S.SelectTitle>
            </S.SelectedBox>
            <S.SelectedBox>
              <img height={18} src="/assets/img/svg/menubar/save_project.svg" />
              <S.SelectTitle>Save Projects</S.SelectTitle>
            </S.SelectedBox>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '5px 15px',
              width: '100%'
            }}
          >
            <span
              style={{
                fontSize: 14,
                fontFamily: DEFAULT_FONT_FAMILY,
                lineHeight: '16px',
                textAlign: 'left',
                color: TEXT_COLOR_NEUTRAL_3,
                margin: '10px 0px'
              }}
            >
              Assistance & Support
            </span>
            <S.SelectedBox
              onClick={() => this.props.handleDesignerAssist(true)}
            >
              <img
                height={18}
                src="\assets\img\svg\lefttoolbar\disigner_assistance.svg"
              />
              <S.SelectTitle>Designer Assistance</S.SelectTitle>
            </S.SelectedBox>
            <S.SelectedBox
              onClick={() => {
                let userInfo = [];
                if (this.props.match) userInfo = this.props.match.params;
                if (
                  this.props.match.params.pid === 'null' ||
                  (sessionStorage.getItem('email') === 'temp_xxx@yyy.com' &&
                    sessionStorage.getItem('firstName') === 'temp_xxx' &&
                    sessionStorage.getItem('lastName') === 'temp_yyy')
                )
                  this.props.handleSubmitPrompt();
                else this.props.handleSubmitForQuote(true);
              }}
            >
              <img
                height={18}
                src="\assets\img\svg\lefttoolbar\reviewforquote.svg"
              />
              <S.SelectTitle>Submit Design</S.SelectTitle>
            </S.SelectedBox>
            <S.SelectedBox onClick={() => this.props.setDownloadPopupVisible(true)}>
              <img height={18} src="\assets\img\svg\toolbar\download.svg" />
              <S.SelectTitle>Download Summary</S.SelectTitle>
            </S.SelectedBox>
            <S.SelectedBox>
              <img height={18} src="/assets/img/svg/bottombar/help.svg" />
              <S.SelectTitle>Help Center</S.SelectTitle>
            </S.SelectedBox>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '5px 15px',
              width: '100%',
              position: 'absolute',
              bottom: 10
            }}
          >
            <S.SelectedBox
              onClick={() => {
                if (!this.props.match.params.token) {
                  this.props.setSignOpen(true);
                }
                this._closePanel();
              }}
            >
              <img height={18} src="/assets/img/svg/menubar/login.svg" />
              <S.SelectTitle>Login</S.SelectTitle>
            </S.SelectedBox>
          </div>
        </div>
      </Drawer>
    );
  }
}

Menubar.propTypes = {
  state: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  content: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  allowProjectFileSupport: PropTypes.bool.isRequired,
  toolbarButtons: PropTypes.array
};

Menubar.contextTypes = {
  projectActions: PropTypes.object.isRequired,
  viewer2DActions: PropTypes.object.isRequired,
  viewer3DActions: PropTypes.object.isRequired,
  linesActions: PropTypes.object.isRequired,
  holesActions: PropTypes.object.isRequired,
  itemsActions: PropTypes.object.isRequired,
  areaActions: PropTypes.object.isRequired,
  translator: PropTypes.object.isRequired,
  catalog: PropTypes.object.isRequired
};
