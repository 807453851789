import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { SnackbarContent } from '@material-ui/core/index';
import Slide from '@material-ui/core/Slide';
import { TEXT_COLOR_NEUTRAL_0 } from '../../../constants';

const SnackBar = ({
  message,
  isOpen = false,
  autoHideDuration = !!autoHideDuration ? autoHideDuration : 5000,
  action = '',
  handleClose,
  snackBarColor = TEXT_COLOR_NEUTRAL_0,
  style
}) => {
  return (
    <Snackbar
      style={style}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      handleClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
      onClose={handleClose}
    >
      <SnackbarContent
        style={{backgroundColor: snackBarColor}}
        action={
          action || (
            <img
              onClick={handleClose}
              style={{ float: 'right', height: 24, cursor: 'pointer' }}
              src="/assets/img/svg/close.svg"
            />
          )
        }
        message={<div dangerouslySetInnerHTML={{ __html: message }} />}
      />
    </Snackbar>
  );
};

export default SnackBar;
