import {BG_COLOR_0, BG_COLOR_HOVER, DEFAULT_FONT_FAMILY, SECONDARY_PURPLE_COLOR, TEXT_COLOR_NEUTRAL_0, TEXT_COLOR_NEUTRAL_1, TEXT_COLOR_NEUTRAL_2, TEXT_COLOR_NEUTRAL_3} from '../../constants';
import styled from 'styled-components';
import MyProjects from './index';

export const DialogWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 60px;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
`

export const Dialog = styled.div`
  position: relative;
  margin: auto;
  width: 600px;
  display: flex;
  flex-direction: column;
  padding: 30px;
  user-select: none;
  text-align: center;
  border-radius: 10px;
  z-index: 10;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 5px -1px rgba(156, 154, 154, 0.2), 0px 6px 10px 0px rgba(156, 154, 154, 0.39), 0px 1px 18px 0px rgba(156, 154, 154, 0.12);
`

export const DialogTitle = styled.span`
  color: ${TEXT_COLOR_NEUTRAL_1};
  font-family: ${DEFAULT_FONT_FAMILY};
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
`;

export const DialogContent = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  width: 100%;
`

export const DialogContentSelection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
`

export const DialogContents = styled.span`
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  padding: 10px 0px;
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
`

export const Select = styled.div`
  border-bottom: 2px solid ${TEXT_COLOR_NEUTRAL_1};
  width: 150px;
  text-align: left;
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_1};
  cursor: pointer;
  :hover{
    border-bottom-color: ${SECONDARY_PURPLE_COLOR};
  }
`

export const ArrowIcon = styled.img`
  position: absolute;
  width: 15px;
  height: 9px;
  background-color: ${TEXT_COLOR_NEUTRAL_3};
  top: 10px;
  right: 10px;
  -webkit-mask-image:url(${props=>props.maskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
`

export const SelectOption = styled.span`
  font-size: 13px;
  padding: 12px 20px;
  font-family: ${DEFAULT_FONT_FAMILY};
  color: ${TEXT_COLOR_NEUTRAL_0};
  font-weight: 600;
  text-align: left;
  cursor: pointer;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

export const SearchInput = styled.input`
  outline: 0;
  border: 2px solid ${TEXT_COLOR_NEUTRAL_3};
  height: 35px;
  padding: 5px;
  padding-left: 35px;
  border-radius: 7px;
`

export const MyProjectsTable = styled.div`
  display: flex;
  flex-direction: column;
`

export const MyProjectsThead = styled.div`
  background-color: ${BG_COLOR_0};
  display: flex;
  align-items: center;
  background-color: #FAFAFF;
`

export const MyProjectsTh = styled.div`
  font-family: ${DEFAULT_FONT_FAMILY};
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  line-height: 13px;
  text-align: left;
  font-weight: 600;
  margin: 5px;
  line-height: normal;
  color: ${TEXT_COLOR_NEUTRAL_2};
`

export const ActionButton = styled.div`
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${SECONDARY_PURPLE_COLOR};
  width: 35px;
  height: 35px;
  padding: 10px;
  :hover{
    background-color: ${BG_COLOR_HOVER};
  }
`

export const TileItem = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  font-family: ${DEFAULT_FONT_FAMILY};
  
@media screen and (min-width : 1024){font-size : 11px;}
@media screen and (max-width : 1024){font-size : 11px;}
@media screen and (min-width : 1366){font-size : 13px;}
@media screen and (max-width : 1366){font-size : 13px;}
@media screen and (min-width : 1440){font-size : 16px;}
@media screen and (max-width : 1440){font-size : 16px;}
  line-height: 13px;
  text-align: left;
  font-weight: 600;
  line-height: normal;
  color: ${TEXT_COLOR_NEUTRAL_2};
  border-radius: 10px;
  border: 2px solid #FAFAFF;
  padding: 15px;
  cursor: pointer;
  margin: 10px;
  :hover{
    background-color: #FAFAFF;
  }
`