import React from 'react';
import Scene3D from './scene3D';

export default function State3D({
  state,
  catalog,
  width,
  height,
  frontRect,
  selectedLayer,
  line_length,
  ceilHeight,
  scale,
  minX,
  minY,
  maxX,
  maxY
}) {
  let { scene } = state;

  return (
    <g>
      {/* <polygon points={`-${width * 0.3},-${height * 0.3} ${width * 0.3},-${height * 0.3} ${width * 0.3},${height * 0.3} -${width * 0.3},${height * 0.3}`} fill="lightgray" />
      <line x1={-(width / 2)} y1={0} x2={width / 2} y2={0} stroke='gray' strokeWidth={1} />
      <line x1={0} y1={height / 2} x2={0} y2={-(height / 2)} stroke='gray' strokeWidth={1} />
      <polygon points={`${width / 2},0 ${(width / 2) - 12},-8 ${(width / 2) - 12},8`} fill="gray" />
      <polygon points={`0,${height / 2} -8,${(height / 2) - 12} 8,${(height / 2) - 12}`} fill="gray" /> */}
      <Scene3D
        scene={scene}
        catalog={catalog}
        width={width}
        height={height}
        mode={state.get('mode')}
        frontRect={frontRect}
        selectedLayer={selectedLayer}
        line_length={line_length}
        ceilHeight={ceilHeight}
        scale={scale}
        minX={minX}
        minY={minY}
        maxX={maxX}
        maxY={maxY}
      />
    </g>
  );
}
