import React from 'react';
import * as S from './styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import {
  SECONDARY_PURPLE_COLOR,
  TEXT_COLOR_NEUTRAL_2,
  TEXT_COLOR_NEUTRAL_5
} from '../../../../constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const StyledDialog = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    border-radius: 10px;
    max-width: unset;
    width: 820px;
    padding: 30px;
  }
`;

const StyledMuiCheckbox = withStyles({
  root: {
    color: TEXT_COLOR_NEUTRAL_2,
    '&$checked': {
      color: SECONDARY_PURPLE_COLOR
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

class DownloadSummaryPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: sessionStorage.getItem("email") || '',
      emailfocusOn: false,
      phone: sessionStorage.getItem("phone") || '',
      phonefocusOn: false,
      firstName: sessionStorage.getItem("firstName") || '',
      firstNamefocusOn: false,
      lastName: sessionStorage.getItem("lastName") || '',
      lastNamefocusOn: false,
      projectTitlefocusOn: false,
      projectTitle: 'Untitled',
      question1: false,
      question2: false,
      question3: false,
    };
  }

  _closePanel() {
    this.props.setDownloadPopupVisible(false);
  }

  render() {
    const { visible } = this.props;
    const {
      firstName,
      firstNameFocusOn,
      lastName,
      lastNameFocusOn,
      email,
      emailFocusOn,
      phone,
      phoneFocusOn,
      projectTitle,
      projectTitleFocusOn,
      question1,
      question2,
      question3,
    } = this.state;

    return (
      <StyledDialog open={visible} onClose={() => this._closePanel()}>
        <div style={{ display: 'flex', width: '100%', userSelect: 'none ' }}>
          <div
            style={{
              width: '50%',
              display: 'flex',
              padding: '20px 40px 20px 20px',
              flexDirection: 'column'
            }}
          >
            <S.DialogTitle>Download Summary</S.DialogTitle>
            <S.DialogContent style={{ marginTop: 10 }}>
              Your are almost there, customize your summary based on your
              requirements.
            </S.DialogContent>
            <S.DialogContent style={{ marginTop: 30 }}>
              Project infromations:
            </S.DialogContent>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={firstName}
                onChange={e => this.setState({ firstName: e.target.value })}
                onFocus={e => this.setState({ firstNameFocusOn: true })}
                onBlur={e => this.setState({ firstNameFocusOn: false })}
                placeholder={firstNameFocusOn ? 'Enter First Name' : 'First Name'}
                required
              />
              {(firstNameFocusOn || !!firstName.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !firstNameFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  First Name
                </S.StyledInputHeadLabel>
              )}
              {!!firstName.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ firstName: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={lastName}
                onChange={e => this.setState({ lastName: e.target.value })}
                onFocus={e => this.setState({ lastNameFocusOn: true })}
                onBlur={e => this.setState({ lastNameFocusOn: false })}
                placeholder={lastNameFocusOn ? 'Enter Last Name' : 'Last Name'}
                required
              />
              {(lastNameFocusOn || !!lastName.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !lastNameFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  Last Name
                </S.StyledInputHeadLabel>
              )}
              {!!lastName.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ lastName: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={email}
                onChange={e => this.setState({ email: e.target.value })}
                onFocus={e => this.setState({ emailFocusOn: true })}
                onBlur={e => this.setState({ emailFocusOn: false })}
                placeholder={emailFocusOn ? 'Enter Email' : 'Email'}
                required
              />
              {(emailFocusOn || !!email.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !emailFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  Email
                </S.StyledInputHeadLabel>
              )}
              {!!email.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ email: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={phone}
                onChange={e => this.setState({ phone: e.target.value })}
                onFocus={e => this.setState({ phoneFocusOn: true })}
                onBlur={e => this.setState({ phoneFocusOn: false })}
                placeholder={
                  phoneFocusOn ? 'Enter Phone Number' : 'Phone Number'
                }
                required
              />
              {(phoneFocusOn || !!phone.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !phoneFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  Phone Number
                </S.StyledInputHeadLabel>
              )}
              {!!phone.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ phone: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.StyledInputWrapper>
              <S.StyledInput
                value={projectTitle}
                onChange={e => this.setState({ projectTitle: e.target.value })}
                onFocus={e => this.setState({ projectTitleFocusOn: true })}
                onBlur={e => this.setState({ projectTitleFocusOn: false })}
                placeholder={
                  projectTitleFocusOn ? 'Enter Project Title' : 'Project Title'
                }
                required
              />
              {(projectTitleFocusOn || !!projectTitle.length) && (
                <S.StyledInputHeadLabel
                  style={{ color: !projectTitleFocusOn && TEXT_COLOR_NEUTRAL_2 }}
                >
                  Project Title
                </S.StyledInputHeadLabel>
              )}
              {!!projectTitle.length && (
                <S.PlusImage
                  maskImage="/assets/img/svg/bottombar/arrow-plus.svg"
                  onClick={() => this.setState({ projectTitle: '' })}
                />
              )}
            </S.StyledInputWrapper>
            <S.DialogContent style={{ margin: '10px 0px' }}>
              What you want to print:
            </S.DialogContent>
            <FormControlLabel
              style={{ margin: '10px 0px' }}
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset' }}
                  checked={question1}
                  onChange={() => this.setState({ question1: !question1 })}
                  value={question1}
                />
              }
              label={
                <S.DialogContents>3D render of the kitchen</S.DialogContents>
              }
            />
            <FormControlLabel
              style={{ margin: '10px 0px' }}
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset' }}
                  checked={question2}
                  onChange={() => this.setState({ question2: !question2 })}
                  value={question2}
                />
              }
              label={
                <S.DialogContents>
                  2D floor plan and elevations technical view
                </S.DialogContents>
              }
            />
            <FormControlLabel
              style={{ margin: '10px 0px' }}
              control={
                <StyledMuiCheckbox
                  style={{ padding: 'unset' }}
                  checked={question3}
                  onChange={() => this.setState({ question3: !question3 })}
                  value={question3}
                />
              }
              label={
                <S.DialogContents>
                  List of parts with quantities and pricing
                </S.DialogContents>
              }
            />
          </div>
          <div
            style={{
              width: '50%',
              padding: '20px 20px 20px 40px',
              borderLeft: `2px solid ${TEXT_COLOR_NEUTRAL_5}`
            }}
          >
          </div>
        </div>
      </StyledDialog>
    );
  }
}

export default DownloadSummaryPopup;
